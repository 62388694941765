import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";

const Footer = () =>{
  const {t, i18n} = useTranslation()
  useEffect(()=>{
    const language = localStorage.getItem("language");
    if(language?.length > 0 ){
      i18n.changeLanguage(language).then(r => {})
    }
  },[])

  return (
      <footer id='footer'>
        <div className='footer-top'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-3 col-md-6 footer-contact'>
                <h3><a href='/home'>Pategou Consulting</a></h3>
                <p>
                  <b>France</b> <br/>{t('footer.bloc-1.france-location')}<br/>
                  {t('footer.bloc-1.phone')} +33 648 37 46 18
                </p>
                <p>
                  <b>{t('footer.bloc-1.cameroon-title')} </b> <br/>{t('footer.bloc-1.cameroon-location')}<br/>
                  {t('footer.bloc-1.cameroon-pobox')}<br/>
                  {t('footer.bloc-1.phone')} (+237) 671 61 90 90 <br/> <span style={{marginLeft:'8.3em'}}>233 42 06 84</span>
                  <br/>{t('footer.bloc-1.whatsapp-phone')} : (+237) 657 25 37 37
                  <br/>
                  <span>{t('footer.bloc-1.mail')}</span> <a target={'_blank'} href={'mailto:contact@pategou.com'}>contact@pategou-consulting.com</a> <br/>
                  <span>{t('footer.bloc-1.web-site')}</span> consulting.pategou.com
                </p>
              </div>

              <div className='col-lg-3 col-md-6 footer-links'>
                <h4>{t('footer.bloc-2.title')}</h4>
                <ul>
                  <li><i className='bx bx-chevron-right'/> <a href='/home'>{t('footer.bloc-2.link-0')}</a></li>
                  <li><i className='bx bx-chevron-right'/> <a href='/home#about'>{t('footer.bloc-2.link-1')}</a></li>
                  <li><i className='bx bx-chevron-right'/> <a href='/home#services'>{t('footer.bloc-2.link-2')}</a></li>
                  <li><i className='bx bx-chevron-right'/> <a href='/home'>{t('footer.bloc-2.link-3')}</a>
                  </li>
                  <li><i className='bx bx-chevron-right'/> <a href='/home'>{t('footer.bloc-2.link-4')}</a></li>
                </ul>
              </div>

              <div className='col-lg-3 col-md-6 footer-links'>
                <h4>{t('footer.bloc-3.title')}</h4>
                <ul>
                  <li><i className='bx bx-chevron-right'/> <a href='/recherche-developpement'>{t('footer.bloc-3.link-0')}</a></li>
                  <li><i className='bx bx-chevron-right'/> <a href='/audit'>{t('footer.bloc-3.link-1')}</a></li>
                  <li><i className='bx bx-chevron-right'/> <a href='/gouvernance-et-strategie'>{t('footer.bloc-3.link-2')}</a></li>
                  <li><i className='bx bx-chevron-right'/> <a href='/integration-de-systeme'>{t('footer.bloc-3.link-3')}</a></li>
                  <li><i className='bx bx-chevron-right'/> <a href='/formations'>{t('footer.bloc-3.link-4')}</a></li>
                </ul>
              </div>

              <div className='col-lg-3 col-md-6 footer-links'>
                <h4>{t('footer.bloc-4.title')}</h4>
                <p>{t('footer.bloc-4.description')}</p>
                <div className='social-links mt-3'>
                  <a href='https://twitter.com/PATEGOUCONSULT?t=KgKW64Ju70dQu4EhIP7nA&s=08' rel='noopener noreferrer' target='_blank' className='twitter'><i
                    className='bx bxl-twitter'></i></a>
                  <a href='https://www.facebook.com/Pategou-Consulting-103003235689793' rel='noopener noreferrer' target='_blank' className='facebook'><i
                    className='bx bxl-facebook'></i></a>
                  <a href='https://www.linkedin.com/company/26105373/admin/' rel='noopener noreferrer' target='_blank' className='linkedin'><i
                      className='bx bxl-linkedin'></i></a>
                  <a href='https://www.instagram.com/pategou_consulting/' rel='noopener noreferrer' target='_blank' className='instagram'><i
                    className='bx bxl-instagram'></i></a>
                  <a href='https://wa.me/237657253737' rel='noopener noreferrer' target='_blank' className='google-plus'><i
                    className='bx bxl-whatsapp'></i></a>

                </div>
                <div className='mt-3 footer-newsletter'>
                  <h4>{t('footer.bloc-4.text-newsletter')}</h4>
                  <div>
                    <input type='email' placeholder={t('footer.bloc-4.text-placeholder')} name='email'/><input type='submit' value={t('footer.bloc-4.text-button-subscribe')}/>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className='container footer-bottom clearfix'>
          <div className='copyright'>
            &copy; Copyright 2022 <strong><span>Pategou Consulting</span></strong>. {t('footer.all-rights-reserved')}
          </div>
          <div className='credits'>
            {t('footer.designed-by')} <a href='https://consulting.pategou.com/'>Pategou Consulting</a>
          </div>
        </div>
      </footer>
    )
}

export default Footer
