import React, {useEffect, useState} from "react";
import Service from "../components/Service"
import Contact from "./Contact"
import clients from "../bd/clients.json"
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from "react-i18next";


const Home = () => {
  const [clientImage, setClientImage] = useState([])
  const {t, i18n} = useTranslation()
  useEffect(()=>{
    const language = localStorage.getItem("language");
    if(language?.length > 0 ){
      i18n.changeLanguage(language).then(r => {})
    }
  },[])

  useEffect(()=>{
    let clientImage = []
    clients.Banks.forEach((bank) => {
      clientImage.push(bank.image)
    })

    clients.Energies.forEach((energy) => {
      clientImage.push(energy.image)
    })

    clients.Industries.forEach((industry) => {
      clientImage.push(industry.image)
    })

    clients.Technologies.forEach((technology) => {
      clientImage.push(technology.image)
    })
    setClientImage(clientImage)
  },[])

    return (
      <main id='main'>
        <section id='hero' className='d-flex align-items-center'>

          <div className='container'>
            <div className='row'>
              <div
                className='col-lg-3 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1'
                data-aos='fade-up' data-aos-delay='200'>
                <h1>PATEGOU CONSULTING</h1>
                <h2>{t('home-banner.text-description')}</h2>
                <div className='d-flex justify-content-center justify-content-lg-start'>
                  <a href='#about' className='btn-get-started scrollto'>{t('home-banner.about')}</a>
                </div>
              </div>
              <div className='col-lg-9 order-1 order-lg-2'>
                <Carousel className='carouselBanner' showThumbs={false} autoPlay={true} useKeyboardArrows={true}
                          infiniteLoop={true}>
                  <div>
                    <img src='assets/img/banniere/rd.jpg' alt='imgRD'/>
                    <p className='legend' style={{background:'#90c26c', opacity:0.9}}>{t('home-banner.slider1')}</p>
                  </div>
                  <div>
                    <img src='assets/img/banniere/audit.jpg' alt='imgAS'/>
                    <p className='legend' style={{background:'#90c26c', opacity:0.9}}>{t('home-banner.slider2')}</p>
                  </div>
                  <div>
                    <img src='assets/img/banniere/gouver.jpg' alt='imgGS'/>
                    <p className='legend' style={{background:'#90c26c', opacity:0.9}}>{t('home-banner.slider3')}</p>
                  </div>
                  <div>
                    <img src='assets/img/banniere/integration.jpg' alt='imgIS'/>
                    <p className='legend' style={{background:'#90c26c', opacity:0.9}}>{t('home-banner.slider4')}</p>
                  </div>
                  <div>
                    <img src='assets/img/banniere/forma.jpg' alt='imgFor'/>
                    <p className='legend' style={{background:'#90c26c', opacity:0.9}}>{t('home-banner.slider5')}</p>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </section>
        <section id='about' className='about'>
          <div className='container' >

            <div className='section-title'>
              <h2>{t('home-about.title')}</h2>
            </div>

            <div className='row content' data-aos='fade-up'>
              <div className='col-lg-6'>
                <p>
                  {t('home-about.paragraph1')}
                </p>
                <br/>
                <p>
                  {t('home-about.paragraph2')}
                </p>
                <br/>
                <p>
                  {t('home-about.paragraph3')}
                </p>
                <p>
                  {t('home-about.paragraph4')} <span><a target={'_blank'} href={'https://www.business-center.pategou.com'}>"PATEGOU BUSINESS CENTER"</a></span> {t('home-about.paragraph4-1')}
                </p>

              </div>
              <div className='col-lg-6 services'>
                <div className='icon-boxx'>
                  <h5>{t('home-about.our-vision-title')}</h5>
                  <p>{t('home-about.our-vision')}</p>
                </div>
                <div className='icon-boxx'>
                  <h5>{t('home-about.our-missions-title')}</h5>
                  <p>{t('home-about.our-missions')}</p>
                </div>
                <div className='icon-boxx'>
                  <h5>{t('home-about.our-values-title')}</h5>
                  <p> {t('home-about.our-values')}</p>
                </div>

              </div>
            </div>

          </div>
        </section>
        <section id='services' className='services bloc_space_wrapper section-bg'>
          <div className='container bloc_space_contain' data-aos='fade-up'>

            <div className='section-title'>
              <h2>{t('home-services.title')}</h2>
              <p>{t('home-services.description')}</p>
            </div>
            <ul className='row'>
              <Service link='/recherche-developpement' colClass='col-xl-4 col-md-6'
                       urlImg='assets/img/banniere/rd.jpg'
                       title={t('home-services.service-1-title')}
                       description={t('home-services.service-1-description')}
              />
              <Service link='/audit' colClass='col-xl-4 col-md-6' urlImg='assets/img/banniere/audit.jpg'
                       title={t('home-services.service-2-title')}
                       description={t('home-services.service-2-description')}
              />
              <Service link='/business-continuity' colClass='col-xl-4 col-md-6' urlImg='assets/img/services/businessContinuityHome.jpg'
                       title={t('home-services.service-6-title')}
                       description={t('home-services.service-6-description')}
              />

            </ul>
            <ul className='row'>
              <Service link='gouvernance-et-strategie' colClass='mt-3 mt-xl-0 col-xl-4 col-md-6'
                       urlImg='assets/img/banniere/gouver.jpg'
                       title={t('home-services.service-4-title')}
                       description={t('home-services.service-4-description')}
              />
              <Service link='integration-de-systeme' colClass='mt-3 mt-xl-0 col-xl-4 col-md-6'
                       urlImg='assets/img/banniere/integration.jpg'
                       title={t('home-services.service-3-title')}
                       description={t('home-services.service-3-description')}
              />
              <Service link='formations' colClass='mt-3 mt-xl-0 col-xl-4 col-md-6'
                       urlImg='assets/img/banniere/forma.jpg'
                       title={t('home-services.service-5-title')}
                       description={t('home-services.service-5-description')}
              />
            </ul>
          </div>
        </section>
        <section  className={'section-bg'}>
          <div className='container'>
            <div className='section-title'>
              <h2>{t('home-references.title')}</h2>
            </div>
            <div className='bloc_partner_wrapper '>
              <div className='bloc_partner_contain container'>
                <div className='bloc_partner_inner'>
                  <div className='title_block_eo_wrapper'>
                    <div className='title_block_eo'>
                      <span className='c_eo'/>

                    </div>
                  </div>
                  <div className='bloc_right'>
                    <ul className='owl-carousel'>
                      {
                        clientImage.map((clientImage, index) => {
                          return (
                            <a key={index} href='/references'><img style={{height:'100px', width:'150px'}} src={clientImage} alt='client'/></a>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Contact home = {true}/>
        <a href='#hero' className='back-to-top d-flex align-items-center justify-content-center'><i
          className='bi bi-arrow-up-short'/></a>
      </main>
    )
}

export default Home
