import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

const Formations = () =>{
  const {t, i18n} = useTranslation()

  useEffect(()=>{
    const language = localStorage.getItem("language");
    if(language?.length > 0 ){
      i18n.changeLanguage(language).then(r => {})
    }
  },[])

    return (
      <div className='top-page'>
        <section id='why-us' className='why-us paddingLeftImg'>
          <div className='container-fluid' data-aos='fade-up'>

            <div className='section-title'>
              <h2><strong>Formations</strong></h2>
            </div>
            <div className='paragraph-section-title'>
              <p>
                {t('services.formation.description')}
              </p>
            </div>
            <div className='row'>
              <div className='col-lg-5 align-items-stretch order-2 order-lg-1 img'
                   style={{ backgroundImage: 'url("assets/img/services/formationSecurite.jpg")' }} data-aos='zoom-in'
                   data-aos-delay='150'>&nbsp;</div>

              <div
                className='col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-1 order-lg-2'>

                <div className='content'>
                  <h3>
                    <strong>{t('services.formation.security.security-title')}</strong>
                  </h3>

                </div>

                <div className='accordion-list2'>
                  <ul>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed sub-accordion'
                           data-bs-target='#accordion-list-1'><span>01</span> <b>
                        {t('services.formation.security.solution-1')}
                           </b></div>
                      <p>
                        {t('services.formation.security.solution-1-description')}
                      </p>
                    </li>

                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-2'
                           className='collapsed sub-accordion'><span>02</span><b>{t('services.formation.security.solution-2')}</b></div>
                      <p>
                        {t('services.formation.security.solution-2-description')}
                      </p>
                    </li>

                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-3'
                           className='collapsed sub-accordion'><span>03</span>
                        <b>{t('services.formation.security.solution-3')} </b></div>
                      <p>
                        {t('services.formation.security.solution-3-description')}
                      </p>
                    </li>

                  </ul>

                </div>

              </div>

            </div>

          </div>
        </section>

        <section id='why-us' className='why-us section-bg paddingRightImg'>
          <div className='container-fluid' data-aos='fade-up'>

            <div className='row'>

              <div
                className='col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1'>

                <div className='content'>
                  <h3>
                    <strong>{t('services.formation.technologies.technologies-title')}</strong>
                  </h3>

                </div>

                <div className='accordion-list'>
                  <ul>
                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-3'
                           className='collapsed sub-accordion'><span>01</span><b>{t('services.formation.technologies.solution-1')} </b></div>
                      <p>
                        {t('services.formation.technologies.solution-1-description')}
                      </p>
                    </li>

                    <li>
                      <div data-bs-toggle='collapse' className='collapsed sub-accordion'
                           data-bs-target='#accordion-list-5'><span>02</span> <b>{t('services.formation.technologies.solution-2')}</b></div>
                      <p>
                        {t('services.formation.technologies.solution-2-description')}
                      </p>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed sub-accordion'
                           data-bs-target='#accordion-list-4'><span>03</span> <b>{t('services.formation.technologies.solution-3')}</b></div>
                      <p>
                        {t('services.formation.technologies.solution-3-description')}
                      </p>
                    </li>

                  </ul>

                </div>

              </div>

              <div className='col-lg-5 align-items-stretch order-1 order-lg-2 img'
                   style={{ backgroundImage: 'url("assets/img/services/formationErp.jpg")' }} data-aos='zoom-in'
                   data-aos-delay='150'>&nbsp;</div>
            </div>

          </div>
        </section>

        <section id='why-us' className='why-us paddingLeftImg '>
          <div className='container-fluid' data-aos='fade-up'>

            <div className='row'>
              <div className='col-lg-5 align-items-stretch order-2 order-lg-1 img'
                   style={{ backgroundImage: 'url("assets/img/services/formationGouvernance.jpg")' }} data-aos='zoom-in'
                   data-aos-delay='150'>&nbsp;</div>

              <div
                className='col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-1 order-lg-2'>

                <div className='content'>
                  <h3>
                    <strong>{t('services.formation.it-governance.governance-title')}</strong>
                  </h3>

                </div>

                <div className='accordion-list2'>
                  <ul>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed sub-accordion'
                           data-bs-target='#accordion-list-1'><span>01</span> <b>{t('services.formation.it-governance.solution-1')} </b></div>
                      <p>
                        {t('services.formation.it-governance.solution-1-description')}
                      </p>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed sub-accordion'
                           data-bs-target='#accordion-list-2'><span>02</span> <b>{t('services.formation.it-governance.solution-2')}  </b></div>
                      <p>
                        {t('services.formation.it-governance.solution-2-description')}
                      </p>
                    </li>

                  </ul>

                </div>

              </div>

            </div>

          </div>
        </section>
        <section id='why-us' className='why-us section-bg paddingRightImg'>
          <div className='container-fluid' data-aos='fade-up'>

            <div className='row'>

              <div
                className='col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1'>

                <div className='content'>
                  <h3>
                    <strong>{t('services.formation.information-management.management-title')} </strong>
                  </h3>

                </div>

                <div className='accordion-list'>
                  <ul>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed sub-accordion'
                           data-bs-target='#accordion-list-11'><span>01</span> <b>{t('services.formation.information-management.solution-1')}</b></div>
                      <p>
                        {t('services.formation.information-management.solution-1-description')}</p>
                    </li>

                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-12'
                           className='collapsed sub-accordion'><span>02</span><b>{t('services.formation.information-management.solution-2')}</b></div>
                      <p>
                        {t('services.formation.information-management.solution-2-description')}
                      </p>
                    </li>

                  </ul>

                </div>

              </div>

              <div className='col-lg-5 align-items-stretch order-1 order-lg-2 img'
                   style={{ backgroundImage: 'url("assets/img/services/formationAnalyse.jpg")' }} data-aos='zoom-in'
                   data-aos-delay='150'>&nbsp;</div>
            </div>

          </div>
        </section>
      </div>
    );
}

export default Formations
