import React, {useEffect, useState} from "react";
import axios from 'axios';
import { myConfig } from "../config";
import {Carousel} from "react-responsive-carousel";
import { useTranslation } from "react-i18next";


const Contact = (props) =>{
  const {t, i18n} = useTranslation()
  useEffect(()=>{
    const language = localStorage.getItem("language");
    if(language?.length > 0 ){
      i18n.changeLanguage(language).then(r => {})
    }
  },[])

  const [name, setName] = useState("")
  const [mail, setMail] = useState("")
  const [etsName, setEtsName] = useState("")
  const [post, setPost] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")


  const handleChange = (input, e) => {
    if (input === "nom") {
      setName(e.target.value);
    }
    if (input === "mail") {
      setMail(e.target.value);

    }
    if (input === "nomEts") {
      setEtsName(e.target.value);
    }
    if (input === "poste") {
      setPost(e.target.value);
    }
    if (input === "sujet") {
      setSubject(e.target.value);
    }
    if (input === "message") {
      setMessage(e.target.value);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const message = {
      to: this.state.nom,
      from: this.state.mail,
      subject: this.state.sujet,
      message: this.state.message
    }
    axios.post(myConfig.apiUrlMail, message)
    .then(result => {
    })
    .catch(exception => {
    })
  }

    return (
      <section id='contact' className= {props.home?'contact section-bg':'contact top-page section-bg'}>
        <div className='container' data-aos='fade-up'>

          <div className='section-title'>
            <h2>{t('contact.title')}</h2>
            <p>{t('contact.description-1')}</p>
            <p>{t('contact.description-2')}</p>
            <h3>{t('contact.description-3')}</h3>
          </div>

          <div className='row'>

            <div className='col-lg-5 d-flex align-items-stretch'>
              <div className='info'>
                <div className='address'>
                  <i className='bi bi-geo-alt'/>
                  <h4>{t('contact.left-form.france.title')}</h4>
                  <p>{t('contact.left-form.france.location')} <br/> <span>{t('contact.left-form.france.phone-title')}</span> +33 648 37 46 18</p>
                </div>

                <iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2622.3636501802375!2d2.3853880157772545!3d48.90846350513352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66c3bce423745%3A0xd45278bf7e590e7a!2s75%20Av.%20de%20la%20R%C3%A9publique%2C%2093300%20Aubervilliers%2C%20France!5e0!3m2!1sfr!2scm!4v1636132851393!5m2!1sfr!2scm'
                    frameBorder='0' title='map' style={{ border: "0", width: "100%", height: "200px", marginBottom:'20px' }}
                    allowFullScreen />
                <div className='address'>
                  <i className='bi bi-geo-alt'/>
                  <h4>{t('contact.left-form.cameroon.title')}</h4>
                  <p>{t('contact.left-form.cameroon.location')} <br/>
                    <span>{t('contact.left-form.cameroon.po-box')} </span> {t('contact.left-form.cameroon.text')} <br/>
                    <span>{t('contact.left-form.cameroon.phone-title')}</span> : (+237) 671 61 90 90 <br/> <span style={{marginLeft:'8.3em'}}>233 42 06 84</span> <br/>
                    <span>{t('contact.left-form.cameroon.whatsapp-phone-title')}</span> : (+237) 657 25 37 37
                  </p>
                </div>

                <iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d287.38915145129687!2d9.695511388401654!3d4.033999610651527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1061130a2d044bd5%3A0xdee229c3e33b1ef!2sPategou%20Business%20Center!5e0!3m2!1sfr!2scm!4v1648050838334!5m2!1sfr!2scm'
                    frameBorder='0' title='map' style={{ border: "0", width: "100%", height: "200px",marginBottom:'20px' }}
                    allowFullScreen />

                <div className='email'>
                  <i className='bi bi-envelope'/>
                  <h4>{t('contact.left-form.mail')}</h4>
                  <p>contact@pategou-consulting.com</p>
                </div>

                <div className='phone'>
                  <i className='bi bi-globe2'/>
                  <h4>{t('contact.left-form.web-site')}</h4>
                  <p>consulting.pategou.com
                  </p>
                </div>

              </div>

            </div>

            <div className='col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch'>
              <form onSubmit={handleSubmit} className='email-form'>
                <div className='row'>
                  <div className='form-group col-md-6'>
                    <label htmlFor='name'>{t('contact.right-form.name')} </label>
                    <input onChange={(e) => handleChange("nom", e)} type='text' name='name'
                           className='form-control' id='name' required/>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='name'>{t('contact.right-form.mail')} </label>
                    <input onChange={(e) => handleChange("mail", e)} type='email' className='form-control'
                           name='email' id='email' required/>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='name'>{t('contact.right-form.phone')} </label>
                    <input onChange={(e) => handleChange("phone", e)} type='text' className='form-control'
                           name='phone' id='phone' required/>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='name'>{t('contact.right-form.company-name')}</label>
                    <input onChange={(e) => handleChange("nomEts", e)} type='text' name='nameEts'
                           className='form-control' id='nameEts' required/>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='name'>{t('contact.right-form.position-held')}</label>
                    <input onChange={(e) => handleChange("poste", e)} type='text' className='form-control'
                           name='post' id='poste' required/>
                  </div>
                  <div className='form-group col-md-6'>
                    <label htmlFor='name'>{t('contact.right-form.topic')}</label>
                    <input onChange={(e) => handleChange("sujet", e)} type='text' className='form-control'
                           name='subject' id='subject' required/>
                  </div>
                </div>

                <div className='form-group'>
                  <label htmlFor='name'>{t('contact.right-form.message')}</label>
                  <textarea onChange={(e) => handleChange("message", e)} className='form-control' name='message'
                            rows='5' required />
                </div>
                <div className='my-3'>
                  <div className='loading'>{t('contact.right-form.loading')}</div>
                  <div className='error-message'/>
                  <div className='sent-message'>{t('contact.right-form.sent-message')}</div>
                </div>
                <p>{t('required-fields')}</p>
                <div className='text-center'>
                  <button onClick={handleSubmit} type='submit'>{t('contact.right-form.send-button-title')}</button>
                </div>
                <div>
                  <Carousel className='carouselContact' showThumbs={false} autoPlay={true} useKeyboardArrows={true}
                            infiniteLoop={true}>
                   {/* <div>
                      <img src='assets/img/buildingFrance.jpg' alt='img siege' title={t('contact.right-form.headquarters-france-title')} style={{width:'100%', marginTop : '20px'}}/>
                      <p className='legend' style={{background:'#90c26c', opacity:0.9}}>{t('contact.right-form.headquarters-france-title')}</p>
                    </div>*/}
                    <div>
                      <img src='assets/img/buildingCameroun.jpg' alt='img siege' title={t('contact.right-form.headquarters-cameroon-title')} style={{width:'100%', marginTop : '20px'}}/>
                      {/*<p className='legend' style={{background:'#90c26c', opacity:0.9}}>{t('contact.right-form.headquarters-cameroon-title')}</p>*/}
                    </div>

                  </Carousel>
                </div>
              </form>

            </div>

          </div>

        </div>
      </section>
    );

}

export default Contact
