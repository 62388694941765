import React from "react";

class Service extends React.Component {
    render() {
        return (
            <li className={this.props.colClass}>
                <div className='bloc_top'><img
                    src={this.props.urlImg} alt='imgService'/></div>
                <div className='bloc_bottom'>
                    <h3>{this.props.title}</h3>
                </div>
                <a href={this.props.link}>
                    <div className='overlay'>
                        <p>{this.props.description}</p>
                    </div>
                </a>
            </li>
        );
    }
}

export default Service
