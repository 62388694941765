import React, {useEffect} from "react";
import Client from "../components/Client"
import clients from "../bd/clients.json"
import {useTranslation} from "react-i18next";

const References = () => {
  const {t, i18n} = useTranslation()
  useEffect(()=>{
    const language = localStorage.getItem("language");
    if(language?.length > 0 ){
      i18n.changeLanguage(language).then(r => {})
    }
  },[])
    return (
      <section id='team' className='team section-bg top-page'>
        <div className='container' data-aos='fade-up'>
          <div className='section-title'>
            <h2>{t('references.title')}</h2>
            <p>{t('references.description')}</p>
          </div>
          <div className='row'>
            <div>
              <h3>
                {t('references.banks.title')}
              </h3>
            </div>
            <br/><br/><br/>
            {
              clients.Banks.map((bank, index) => {
                return (
                  <Client key={index} colClass={bank.colClass} delayAos={bank.delayAos} image={bank.image}
                          name={bank.name}
                          slogan={bank.slogan} services={bank.services}/>
                )
              })
            }
          </div>
          <br/><br/>
          <div className='row'>
            <div>
              <h3>
                {t('references.technologies.title')}
              </h3>
            </div>
            <br/><br/><br/>
            {
              clients.Technologies.map((technologie, index) => {
                return (
                  <Client key={index} colClass={technologie.colClass} delayAos={technologie.delayAos}
                          image={technologie.image} name={technologie.name}
                          slogan={technologie.slogan} services={technologie.services}/>
                )
              })
            }
          </div>
          <br/><br/>
          <div className='row'>
            <div>
              <h3>
                {t('references.energies.title')}
              </h3>
            </div>
            <br/><br/><br/>
            {
              clients.Energies.map((energie, index) => {
                return (
                  <Client key={index} colClass={energie.colClass} delayAos={energie.delayAos} image={energie.image}
                          name={energie.name}
                          slogan={energie.slogan} services={energie.services}/>
                )
              })
            }
          </div>
          <br/><br/>
          <div className='row'>
            <div>
              <h3>
                {t('references.industries.title')}
              </h3>
            </div>
            <br/><br/><br/>
            {
              clients.Industries.map((industrie, index) => {
                return (
                  <Client key={index} colClass={industrie.colClass} delayAos={industrie.delayAos}
                          image={industrie.image} name={industrie.name}
                          slogan={industrie.slogan} services={industrie.services}/>
                )
              })
            }
          </div>
        </div>
      </section>
    );
}

export default References
