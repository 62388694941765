import './App.css';
import React from "react";
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import BusinessContinuity from "./pages/services/BusinessContinuity"
import Audit from "./pages/services/Audit"
import Gouvernance from "./pages/services/Gouvernance"
import Integration from "./pages/services/Integration"
import Formations from "./pages/services/Formations"
import References from "./pages/References"
import Contact from "./pages/Contact"
import RechercheDeveloppement from "./pages/services/RechercheDeveloppement";

class App extends React.Component {
  render() {
    return (
      <div>
        <div>
          <Router>
            <Header/>
            <Switch>
              <Redirect from='/' to='home' exact/>
              <Route exact path='/home' component={Home}/>
              <Route exact path='/business-continuity' component={BusinessContinuity}/>
              <Route exact path='/audit' component={Audit}/>
              <Route exact path='/gouvernance-et-strategie' component={Gouvernance}/>
              <Route exact path='/integration-de-systeme' component={Integration}/>
              <Route exact path='/formations' component={Formations}/>
              <Route exact path='/references' component={References}/>
              <Route exact path='/contact' component={Contact}/>
              <Route exact path='/recherche-developpement' component={RechercheDeveloppement}/>
            </Switch>
          </Router>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default App;
