import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

const RechercheDeveloppement = () =>{
  const {t, i18n} = useTranslation()
  useEffect(()=>{
    const language = localStorage.getItem("language");
    if(language?.length > 0 ){
      i18n.changeLanguage(language).then(r => {})
    }
  },[])
    return (
      <div className='top-page'>
        <section id='why-us' className='why-us paddingLeftImg'>
          <div className='container-fluid' data-aos='fade-up'>
            <div className='section-title'>
              <h2><strong>{t('services.research.title')}</strong></h2>
            </div>
            <div className='paragraph-section-title'>
              <p>
                {t('services.research.paragraph-1')}
              </p>
              <p dangerouslySetInnerHTML={{__html : t('services.research.paragraph-2')}}></p>
              <p dangerouslySetInnerHTML={{__html : t('services.research.paragraph-3')}}></p>
            </div>
            <div className='row'>
              <div className='col-lg-5 align-items-stretch order-2 order-lg-1 img'
                   style={{ backgroundImage: 'url("assets/img/services/recherchePreocc.jpg")' }} data-aos='zoom-in'
                   data-aos-delay='150'>&nbsp;</div>

              <div
                className='col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-1 order-lg-2'>

                <div className='content'>
                  <h3>
                    <strong> {t('services.research.your-concerns-title')}</strong>
                  </h3>
                </div>

                <div className='accordion-list2'>
                  <ul>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-1'><span>01</span> {t('services.research.concern-1')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-2'
                           className='collapsed'><span>02</span>{t('services.research.concern-2')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-3'
                           className='collapsed'><span>03</span>{t('services.research.concern-3')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-3'
                           className='collapsed'><span>04</span>{t('services.research.concern-4')}
                      </div>
                    </li>
                  </ul>

                </div>

              </div>

            </div>

          </div>
        </section>

        <section id='why-us' className='why-us section-bg paddingRightImg'>
          <div className='container-fluid' data-aos='fade-up'>
            <div className='row'>
              <div
                className='col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1'>
                <div className='content'>
                  <h3>
                    <strong>{t('services.research.our-solutions-title')}</strong>
                  </h3>
                </div>
                <div className='accordion-list'>
                  <ul>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-1'><span>01</span> {t('services.research.solution-1')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-2'><span>02</span> {t('services.research.solution-2')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-3'><span>03</span>{t('services.research.solution-3')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-4'><span>04</span>{t('services.research.solution-4')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-5'><span>05</span> <b>{t('services.research.solution-5')} </b>
                      </div>
                      <p>
                        {t('services.research.solution-5-description')}
                      </p>
                    </li>
                  </ul>

                </div>
              </div>

              <div className='col-lg-5 align-items-stretch order-1 order-lg-2 img'
                   style={{ backgroundImage: 'url("assets/img/services/rechercheSol.jpg")', marginRight: "0px" }}
                   data-aos='zoom-in'
                   data-aos-delay='150'>&nbsp;</div>
            </div>
          </div>
        </section>
      </div>

    );
}

export default RechercheDeveloppement
