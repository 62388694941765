import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

const BusinessContinuity = () => {
  const {t, i18n} = useTranslation()
  useEffect(()=>{
    const language = localStorage.getItem("language");
    if(language?.length > 0 ){
      i18n.changeLanguage(language).then(r => {})
    }
  },[])
    return (
      <div className='top-page'>
        <section id='conseil' className='why-us section-bg paddingRightImg'>
          <div className='container-fluid' data-aos='fade-up'>

            <div className='section-title'>
              <h2><strong>Business Continuity 360</strong></h2>
            </div>
            <div className='paragraph-section-title paddingLeftImg'>
              <p>
                {t('services.business-continuity.description-1')}
              </p>
              <p>
                <div dangerouslySetInnerHTML={{__html : t('services.business-continuity.description-2')}}></div>
                <div className={'businessContinuity'} >
                  <div>
                    <img  src={'assets/img/services/BusinessContinuity360.png'} alt={'businessContinuity'}/>
                  </div>
                </div>
                <div dangerouslySetInnerHTML={{__html : t('services.business-continuity.description-3')}}></div>

                {/*{t('services.business-continuity.description-3')}*/}
              </p>
            </div>
            <div className='row'>

              <div
                className='col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1'>

                <div className='content'>
                  <h3>
                    <strong>{t('services.business-continuity.advise-title')}</strong>
                  </h3>
                  <p>
                    {t('services.business-continuity.advise-description')}
                  </p>
                </div>

                <div className='accordion-list'>
                  <ul>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-1'><span>01</span> {t('services.business-continuity.advise-1')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-2'><span>02</span> {t('services.business-continuity.advise-2')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-3'><span>03</span>{t('services.business-continuity.advise-3')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-4'><span>04</span>{t('services.business-continuity.advise-4')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-5'><span>05</span>{t('services.business-continuity.advise-5')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-6'><span>06</span>{t('services.business-continuity.advise-6')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-7'><span>07</span>{t('services.business-continuity.advise-7')}
                      </div>
                    </li>
                  </ul>

                </div>
              </div>

              <div className='col-lg-5 align-items-stretch order-1 order-lg-2 img'
                   style={{ backgroundImage: 'url("assets/img/services/businessContinuityConseil.jpg")' }} data-aos='zoom-in'
                   data-aos-delay='150'>&nbsp;</div>
            </div>

          </div>
        </section>

        <section id='formation' className='why-us section-bg2 paddingLeftImg'>
          <div className='container-fluid' data-aos='fade-up'>

            <div className='row'>
              <div className='col-lg-5 align-items-stretch order-2 order-lg-1 img'
                   style={{ backgroundImage: 'url("assets/img/services/businessContinuityFormation.jpg")' }} data-aos='zoom-in'
                   data-aos-delay='150'>&nbsp;</div>

              <div
                className='col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-1 order-lg-2'>

                <div className='content'>
                  <h3>
                    <strong>Formation</strong>
                  </h3>
                  <p>
                    {t('services.business-continuity.formation-description-1')}
                  </p>


                </div>

                <div className='accordion-list2'>
                  <ul>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-6'><span>01</span> {t('services.business-continuity.formation-1')}
                      </div>
                    </li>

                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-7'
                           className='collapsed'><span>02</span>{t('services.business-continuity.formation-2')}
                      </div>

                    </li>

                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-8'
                           className='collapsed'><span>03</span> {t('services.business-continuity.formation-3')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-9'
                           className='collapsed'><span>04</span>
                        {t('services.business-continuity.formation-4')}
                      </div>

                    </li>
                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-10'
                           className='collapsed'><span>05</span>{t('services.business-continuity.formation-5')}
                      </div>

                    </li>

                  </ul>
                  <p>{t('services.business-continuity.formation-description-2')}
                  </p>

                </div>

              </div>

            </div>

          </div>
        </section>

        <section id='logiciel' className='why-us section-bg paddingRightImg'>
          <div className='container-fluid' data-aos='fade-up'>

            <div className='row'>

              <div
                className='col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1'>

                <div className='content'>
                  <h3>
                    <strong>
                      {t('services.business-continuity.pdrweb-title')}
                    </strong>
                  </h3>

                  <p>
                    {t('services.business-continuity.pdrweb-description-1')}
                  </p>

                </div>

                <div className='accordion-list'>
                  <ul>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed'
                           data-bs-target='#accordion-list-1'><span>01</span> {t('services.business-continuity.pdrweb-1')}
                      </div>
                    </li>

                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-2'
                           className='collapsed'><span>02</span>{t('services.business-continuity.pdrweb-2')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' className='collapsed sub-accordion'
                           data-bs-target='#accordion-list-3'><span>03</span>{t('services.business-continuity.pdrweb-3')}
                      </div>
                      <p dangerouslySetInnerHTML={{__html : t('services.business-continuity.pdrweb-3-description')}}></p>
                    </li>

                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-4'
                           className='collapsed'><span>04</span>{t('services.business-continuity.pdrweb-4')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-5'
                           className='collapsed'><span>05</span>{t('services.business-continuity.pdrweb-5')}
                      </div>
                    </li>
                    <li>
                      <div data-bs-toggle='collapse' data-bs-target='#accordion-list-6'
                           className='collapsed'><span>06</span>{t('services.business-continuity.pdrweb-6')}
                      </div>
                    </li>

                  </ul>
                  <p>
                    {t('services.business-continuity.pdrweb-description-2')}
                  </p>
                </div>

              </div>

              <div className='col-lg-5 align-items-stretch order-1 order-lg-2 img'
                   style={{ backgroundImage: 'url("assets/img/services/pdrweb.png")' }} data-aos='zoom-in'
                   data-aos-delay='150'>&nbsp;</div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default BusinessContinuity
