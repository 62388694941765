import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

const Client = (props) => {
  const {t, i18n} = useTranslation()
  useEffect(()=>{
    const language = localStorage.getItem("language");
    if(language?.length > 0 ){
      i18n.changeLanguage(language).then(r => {})
    }
  },[])
    return (
      <div className={"col-lg-6 heightClient " + props.colClass}>
        <div className='member d-flex align-items-start' data-aos='zoom-in' data-aos-delay={props.delayAos}>
          <div className='pic'><img src={props.image} style={{height:'100px', width:'150px'}} className='img-fluid' alt='imgC'/>
          </div>
          <div className='member-info'>
            <h4>{props.name}</h4>
            <span/>
            <ul style={{marginTop:'10px'}}>
              {props.services.map((service, index) => {
                return (
                  <div key={index}><i className='bx bx-chevron-right'/>{t(`${service}`)}</div>
                )
              })}
            </ul>

          </div>
        </div>
      </div>
    );
}

export default Client
