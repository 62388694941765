import React, {useEffect} from "react";
import Lang from "./Lang";
import { useTranslation } from "react-i18next";


const Header = () => {

    const {t, i18n} = useTranslation()
    useEffect(()=>{
        const language = localStorage.getItem("language");
        if(language?.length > 0 ){
            i18n.changeLanguage(language).then(r => {})
        }
    },[])

    const homeClass = (window.location.pathname.match(/^\/home/) || window.location.pathname === "/")
      ? "nav-link scrollto active" : "nav-link scrollto";
    let aboutClass = "";
    if (window.location.pathname.match(/^\/vision/) || window.location.pathname.match(/^\/mission/)
      || window.location.pathname.match(/^\/valeurs/)) {
      aboutClass = "active"
    }
    let serviceClass = "";
    if (window.location.pathname.match(/^\/recherche-developpement/) || window.location.pathname.match(
      /^\/business-continuity/) || window.location.pathname.match(/^\/audit/) || window.location.pathname.match(
      /^\/gouvernance-et-strategie/) || window.location.pathname.match(/^\/integration-de-systeme/)
      || window.location.pathname.match(/^\/formations/)) {
      serviceClass = "active"
    }
    const contactClass = window.location.pathname.match(/^\/contact/) ? "nav-link scrollto active"
      : "nav-link scrollto";
    const referenceClass = window.location.pathname.match(/^\/references/) ? "nav-link scrollto active"
      : "nav-link scrollto";

    return (
      <header id='header' className='fixed-top'>
        <div className='container d-flex align-items-center'>

          <h1 className='logo me-auto'><a href='/home'><img src='/assets/img/pategouconsulting.png' alt='logo'/></a></h1>
          <nav id='navbar' className='navbar'>
            <ul>
              <li><a className={homeClass} href='/home'>{t('navigation-menu.home')}</a></li>
              <li><a className={aboutClass} href='/home#about'>{t('navigation-menu.about')}</a>
              </li>
              <li className='dropdown'><a className={serviceClass} href='/home#services'><span>Services</span> <i
                className='bi bi-chevron-down'/></a>
                <ul>
                  <li><a href='/recherche-developpement'>{t('navigation-menu.research')}</a></li>
                  <li className='dropdown'><a href='/audit'><span>{t('navigation-menu.audit')}</span> <i
                    className='bi bi-chevron-right'/></a>
                    <ul>
                      <li><a href='/business-continuity'>{t('navigation-menu.business-continuity')}</a></li>
                    </ul>
                  </li>
                  <li><a href='/gouvernance-et-strategie'>{t('navigation-menu.governance')}</a></li>
                  <li><a href='/integration-de-systeme'>{t('navigation-menu.system-integration')}</a></li>
                  <li><a href='/formations'>{t('navigation-menu.formations')}</a></li>
                </ul>
              </li>
              <li><a className={referenceClass} href='/references'>{t('navigation-menu.references')}</a></li>
              <li><a className={contactClass} href='/contact'>Contact</a></li>
              <li className={'nav-link scrollto'}>
                <Lang  />
              </li>
            </ul>
            <i className='bi bi-list mobile-nav-toggle'/>
          </nav>

        </div>
      </header>
    )
}

export default Header
